<script>
import Topbar from '@components/topbar'
import RightSidebar from '@components/right-sidebar'

export default {
    components: {
        Topbar,
        RightSidebar
    },
    data() {
        return {
            showMobileMenu: false,
        }
    },
    created: () => {
        document.body.classList.remove('authentication-bg')
        document.body.classList.remove('authentication-bg-pattern')
        document.body.setAttribute('data-layout', 'topnav')
        document.body.classList.remove('right-bar-enabled')
        document.body.classList.remove('left-side-menu-condensed')
        document.body.classList.remove('boxed-layout')
    },
    methods: {
        toggleMenu() {
            this.showMobileMenu = !this.showMobileMenu
        },
        toggleRightSidebar() {
            document.body.classList.toggle('right-bar-enabled')
        },
    },
}
</script>

<template>
    <div>
        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->
        <Topbar :is-menu-opened="showMobileMenu" />
        <div class="topnav shadow-sm">
        </div>
        <div class="content-page">
            <div class="content">
                <div class="container-fluid">
                    <slot />
                </div>
            </div>
        </div>
        <RightSidebar />
    </div>
</template>
