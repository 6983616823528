<script>
import {
    layoutComputed
} from '@state/helpers'
import Topbar from '@components/topbar'
import Rightsidebar from '@components/right-sidebar'
import Footer from '@components/footer'

export default {
    components: {
        Topbar,
        Rightsidebar,
        Footer
    },
    data() {
        return {
            isMenuCondensed: false,
            routeTitle: "",
            isMobileMenuOpened: false,
            loggedInCompany: null,
            companies: null,
            routeTitle: this.$route.meta.pageTitle,
            layout: this.$store ? this.$store.state.layout.layoutType : null || null,
            theme: this.$store ?
                this.$store.state.layout.leftSidebarTheme : null || null,
            type: this.$store ?
                this.$store.state.layout.leftSidebarType : null || null,
            width: this.$store ? this.$store.state.layout.layoutWidth : null || null,
        }
    },
    computed: {
        ...layoutComputed,
    },
    watch: {
        '$route'() {
            this.routeTitle = this.$route.meta.pageTitle
        },
    },
    async created() {
        document.body.classList.remove('authentication-bg')
        document.body.classList.remove('authentication-bg-pattern')
        document.body.removeAttribute('data-layout')
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
                navigator.userAgent
            )
        ) {
            if (window.screen.width >= 728 && window.screen.width <= 1028) {
                document.body.classList.add('left-side-menu-condensed')
            }
        }
    },
    computed: {
        isProgressing() {
            return this.$store.state.isProgressing
        }
    },
    methods: {
        changeRouteTitle(title) {
            console.log("🚀 ~ file: vertical.vue ~ line 56 ~ changeRouteTitle ~ title", title)
            this.routeTitle = title
        },
        toggleMenu() {
            document.body.classList.toggle('left-side-menu-condensed')
            this.isMenuCondensed = !this.isMenuCondensed
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
                    navigator.userAgent
                )
            ) {
                this.isMobileMenuOpened = !this.isMobileMenuOpened
                document.body.classList.toggle('sidebar-enable')
                if (window.screen.width <= 425) {
                    document.body.classList.remove('left-side-menu-condensed')
                }
            }
        },
        toggleRightSidebar() {
            document.body.classList.toggle('right-bar-enabled')
        },
        hideRightSidebar() {
            document.body.classList.remove('right-bar-enabled')
        },
    },
}
</script>

<template>
    <div id="wrapper">
        <Topbar :routeTitle="routeTitle" :is-menu-opened="isMobileMenuOpened" />
        <div class="content-page">
            <div class="row">
                <vs-progress indeterminate color="primary" v-show="isProgressing"></vs-progress>
            </div>
            <div class="content">
                <slot />
                <vue-page-transition name="fade-in-right">
                    <router-view />
                </vue-page-transition>
            </div>
            <Footer />
        </div>
        <!-- <Rightsidebar /> -->
    </div>
</template>
