<script>
import {
    layoutComputed
} from '@state/helpers'
import Vertical from '@router/layouts/vertical'
import Horizontal from '@router/layouts/horizontal'

export default {
    components: {
        Vertical,
        Horizontal
    },
    data() {
        return {
            deviceResult: null,
        }
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
    created() {
        console.log("loading", this.layoutType);
        const parser = new UAParser()
        this.deviceResult = parser.getResult()
        console.log(this.deviceResult)
    }
}
</script>

<template>
    <div>
        <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
            <slot />
        </Vertical>

        <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
            <slot />
        </Horizontal>

    </div>
</template>
